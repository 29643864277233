import React, { useEffect } from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import issgLogo from '../../images/logo-2.png';

const App = () => {

  useEffect(() => {
    window.location = 'https://www.issglobal.co.uk'
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <img src={issgLogo} />
      </div>
    </>
  );
}

export default App;